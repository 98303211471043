import HttpClient from "@/http/httpClient";
import type {
  EmailNotificationsPreferencesType,
  GetMeResponseType,
  GetUsersPermissionsResponseType,
} from "./types";
import type { WorkspaceType } from "../workspaces/types";

export async function getUsersWorkspaces({ signal }: { signal?: AbortSignal }) {
  return await HttpClient.request().get<GetUsersWorkspacesResponseType>(
    "users/workspaces",
    { signal },
  );
}

interface GetUsersWorkspacesResponseType {
  workspaces: WorkspaceType[];
}

export async function getUsersPermissions({
  signal,
}: {
  signal?: AbortSignal;
}) {
  return await HttpClient.request().get<GetUsersPermissionsResponseType>(
    "users/permissions",
    { signal },
  );
}

export async function getMe({ signal }: { signal?: AbortSignal }) {
  return await HttpClient.request().get<GetMeResponseType>("users/me", {
    signal,
  });
}

export async function deleteUser(id: string) {
  return await HttpClient.request().delete(`users/${id}`);
}

export async function postEmailNotificationPreferences(
  preferences: EmailNotificationsPreferencesType,
) {
  return HttpClient.request().post("users/me/email-notifications-preferences", {
    json: preferences,
  });
}
