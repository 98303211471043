import HttpClient from "@/http/httpClient";
import type {
  ChatbotMessageResponseType,
  ChatbotSavedConversationsRequestType,
  getChatbotFollowUpsRequestType,
  getChatbotFollowUpsResponseType,
  getChatbotInfoRequestType,
  getChatbotRegulatoryUpdatesResponseType,
  getChatbotSourcesResponseType,
  updateChatbotRequestType,
} from "./types";
import type { workspaceChatbotType } from "../workspaces/types";

export async function getChatbotInfo(request: getChatbotInfoRequestType) {
  return HttpClient.request().get(`chatbots/${request.chatbotID}`);
}

export async function getChatbotSavedConversations(
  request: ChatbotSavedConversationsRequestType,
) {
  return HttpClient.request().get(
    `chatbots/${request.chatbotID}/conversations`,
  );
}

export async function loadChatbotConversation(request: {
  conversationID: string;
}) {
  return HttpClient.request().get(`conversations/${request.conversationID}`);
}

export async function saveChatbotConversation(request: {
  conversationID: string;
  name: string;
}) {
  return HttpClient.request().put(`conversations`, {
    json: {
      conversation_name: request.name,
      conversation_id: request.conversationID,
      is_saved_by_user: true,
    },
  });
}

export async function getChatbotSources(request: { chatbotID: string }) {
  return HttpClient.request().get<getChatbotSourcesResponseType>(
    `chatbots/${request.chatbotID}/sources`,
  );
}

export async function getChatbotRegulatoryUpdates(request: {
  chatbotID: string;
}) {
  return HttpClient.request().get<getChatbotRegulatoryUpdatesResponseType>(
    `chatbots/${request.chatbotID}/regulatory-updates`,
  );
}

export async function deleteChatbotSource(request: { sourceID: string }) {
  return HttpClient.request().delete(`sources/${request.sourceID}`);
}

export async function getSuggestedQuestions(
  request: getChatbotFollowUpsRequestType,
) {
  return HttpClient.request().post<getChatbotFollowUpsResponseType>(
    `ai/chatbot-follow-ups`,
    {
      json: request,
    },
  );
}

export async function updateChatbotInfo(request: {
  chatbotID: string;
  chatbotInfo: updateChatbotRequestType;
}) {
  return HttpClient.request().put(`chatbots/${request.chatbotID}`, {
    json: request.chatbotInfo,
  });
}

export async function deleteChatbot(chatbotID: string) {
  return HttpClient.request().delete(`chatbots/${chatbotID}`);
}

export async function postChatbot(request: {
  chatbotName: string;
  sourceIDs: string[];
  workspaceID: string;
  chatbotType: "regular" | "a-to-b";
}) {
  return HttpClient.request().post<workspaceChatbotType>(`chatbots/create`, {
    json: {
      name: request.chatbotName,
      source_ids: request.sourceIDs,
      workspace_id: request.workspaceID,
      type: request.chatbotType,
    },
  });
}

export async function postChatbotExport({
  chatbotId,
  exportType,
  messages,
}: {
  chatbotId: string;
  messages: ChatbotMessageResponseType[];
  exportType?: string;
}) {
  return await HttpClient.request()
    .ky()(`chatbots/${chatbotId}/export`, {
      method: "POST",
      json: {
        export_type: exportType ?? "docx",
        messages,
      },
    })
    .blob();
}
